import React from 'react'
import get from 'lodash/get'
import { graphql, useStaticQuery } from 'gatsby'
import Card from 'react-bootstrap/Card'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import styles from './post-card.scss'

const PostCardContainer = ({containerClassName, imgElement, props}) => {
  return (
    <div className={ containerClassName }>
      <Card>
        <Link to={ props.url } className="">{ imgElement }</Link>
        <Card.Body>
          { props.category ? (<div className="post-category">{ props.category }</div>) : ''}
          <Card.Title as="div"><Link to={ props.url } className="text-graydark">{ props.title }</Link></Card.Title>
        </Card.Body>
        <Card.Footer>
          <Link to={ props.url } className="btn btn-success">READ MORE</Link>
        </Card.Footer>
      </Card>
    </div>
  )
}

const PostCardContainerV2 = ({containerClassName, imgElement, props}) => {
  return (
    <div className={`${containerClassName} post-card-v2`}>
      <Link to={ props.url }>
        <Card className={`${props.bgColor ? props.bgColor : ''}`}>
          { imgElement }
          <Card.Body>
            <Card.Title as="div">
              <div className="text-black title-link">{ props.title }</div>
              { props.shortDescription ? <div className="short-description">{props.shortDescription}</div> : ''}
            </Card.Title>
          </Card.Body>
          <Card.Footer className="d-flex">
            <div className="col-6 p-0 text-left">
              <div className="text-primary font-weight-bold read-more">Read More</div>
            </div>
            { props.category ? (<div className="col-6 p-0 post-category d-flex align-items-center justify-content-end">{ props.category }&nbsp;&nbsp;<span className="dot"></span></div>) : ''}
          </Card.Footer>
        </Card>
      </Link>
    </div>
  )
}

class PostCard extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {    
    let imgElement = ''
    const containerClassName = `post-card ${this.props.className ? this.props.className : ''}`
    if (this.props.image) {
      if (this.props.image.fluid) {
        imgElement = (<Img className="card-img-top" fluid={ this.props.image.fluid } alt={ this.props.title }/>)
      } else if (this.props.image.fixed) {
        imgElement = (<Img className="card-img-top" fixed={ this.props.image.fixed } alt={ this.props.title }/>)
      } else if (this.props.image.file && this.props.image.file.url) {
        imgElement = (<img className="card-img-top lazyload" src={`${this.props.image.file.url}?w=350`} alt={ this.props.title }/>)
      }
    } else {
      imgElement = (<Img className="card-img-top" fluid={ this.props.data.postPlaceholder.childImageSharp.fluid } alt={ this.props.title }/>)
    }

    return this.props.version === 2 ?
      <PostCardContainerV2 containerClassName={containerClassName} imgElement={imgElement} props={this.props} />
      : <PostCardContainer containerClassName={containerClassName} imgElement={imgElement} props={this.props} />

  }
}

export default (props) => {  
  const data = useStaticQuery(graphql`
    query PostCardQuery {
      postPlaceholder: file(relativePath: { eq: "post-placeholder.jpg" }) {
        childImageSharp {
          fluid(maxHeight: 180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <PostCard  {...props} data={data}/>
  )
};
