import React from "react";
import AddThis from "../add-this/add-this";
import styles from './sticky-share.scss'
import { WIDTH_BREAKPOINTS } from '../../constants'
import {getElementSizeBySelector, getHeaderSize} from "../../helpers";

class StickyShare extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      addThisEnabled: false
    }

    this.calculateAnchorOffset = this.calculateAnchorOffset.bind(this)
    this.enableAddThis = this.enableAddThis.bind(this)
    this.handleScroll = this.handleScroll.bind(this)
    this.showShareDialog = this.showShareDialog.bind(this)
  }

  componentDidMount() {
    setTimeout(this.enableAddThis, 0)

    /** Settings below will be used by the "share" bar **/
    this.previousScrollYOffset = window.pageYOffset
    this.sharingOptionsHidden = true
    window.addEventListener('scroll', this.handleScroll)
    this.calculateAnchorOffset()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }


  calculateAnchorOffset() {
    const headerSize = getHeaderSize()
    this.anchorsOffset = headerSize.height
  }

  /**
   * Enables AddThis in case it's desktop
   * @return {void}
   */
  enableAddThis() {
    if (window.outerWidth >= WIDTH_BREAKPOINTS.lg) {
      this.setState({
        addThisEnabled: true
      })
    }
  }

  /**
   * Handles scroll (shows/hide sharing options)
   * @param  {Event} e
   * @return {void}
   */
  handleScroll(e) {
    const currentPageYOffset = window.pageYOffset

    if (currentPageYOffset < this.previousScrollYOffset && !this.sharingOptionsHidden) {
      this.sharingOptionsRef.className = `sharing-options ${this.props.className ? this.props.className : ''} open`
      this.sharingOptionsHidden = true
    } else if (currentPageYOffset >= this.previousScrollYOffset && this.sharingOptionsHidden) {
      this.sharingOptionsRef.className = `sharing-options ${this.props.className ? this.props.className : ''} closed`
      this.sharingOptionsHidden = false
    }

    this.previousScrollYOffset = currentPageYOffset;
  }

  showShareDialog(e) {
    e && e.preventDefault();
    navigator && navigator.share && navigator.share({
      title: this.props.shareDialogTitle,
      text: this.props.shareDialogText,
      url: location.href,
    });
  }

  render() {
    return (
      <div className={`sharing-options ${this.props.className ? this.props.className : ''}`} ref={(ref) => this.sharingOptionsRef = ref}>
        <div className="sharing-options-inner">
          <span className="d-lg-none">1.7k Shares</span>
          { !this.state.addThisEnabled ? (
            <div className="sharing-options-networks">
              <a href="#" onClick={(e) => this.showShareDialog(e, 'facebook')} title="Share on Facebook">
                <img src="/img/the-great-hemp-scam/blog-main/social-facebook.svg" className="sharing-option" alt="facebook" />
              </a>
              <a href="#" onClick={(e) => this.showShareDialog(e, 'twitter')} title="Share on Twitter">
                <img src="/img/the-great-hemp-scam/blog-main/social-twitter.svg" className="sharing-option" alt="twitter" />
              </a>
              <a href="#" onClick={(e) => this.showShareDialog(e, 'linkedin')} title="Share on Linkedin">
                <img src="/img/the-great-hemp-scam/blog-main/social-linkedin.svg" className="sharing-option" alt="linkedin" />
              </a>
              <a href="#" onClick={(e) => this.showShareDialog(e, 'pinterest')} title="Share on Pinterest">
                <img src="/img/the-great-hemp-scam/blog-main/social-pinterest.svg" className="sharing-option" alt="pinterest" />
              </a>
              <a href="#" onClick={(e) => this.showShareDialog(e, 'blogger')} title="Share on Blogger">
                <img src="/img/the-great-hemp-scam/blog-main/social-blogger.svg" className="sharing-option" alt="blogger" />
              </a>
            </div>
          ) : (
            <AddThis className="addthis_default_style sharing-options-networks">
              <a className="addthis_button_facebook" title="Share on Facebook"><img src="/img/the-great-hemp-scam/blog-main/social-facebook.svg" className="sharing-option" alt="facebook" /></a>
              <a className="addthis_button_twitter" title="Share on Twitter"><img src="/img/the-great-hemp-scam/blog-main/social-twitter.svg" className="sharing-option" alt="twitter" /></a>
              <a className="addthis_button_pinterest" title="Share on Pinterest"><img src="/img/the-great-hemp-scam/blog-main/social-pinterest.svg" className="sharing-option" alt="pinterest" /></a>
              <a className="addthis_button_linkedin" title="Share on Linkedin"><img src="/img/the-great-hemp-scam/blog-main/social-linkedin.svg" className="sharing-option" alt="linkedin" /></a>
              <a className="addthis_button_blogger" title="Share on Blogger"><img src="/img/the-great-hemp-scam/blog-main/social-blogger.svg" className="sharing-option" alt="blogger" /></a>
            </AddThis>
          ) }
          <span className="d-none d-lg-block">1.7k Shares</span>
        </div>
      </div>
    )
  }
}

export default StickyShare