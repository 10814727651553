import React,{useState} from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/theme/layout'
import SEO from '../components/seo'
import StickyShare from '../components/sticky-share/sticky-share'
import styles from '../scss/page.scss'
import { buildBreadcrumbsItem } from "../helpers.js"
import PostCard from "../components/contentful/post-card";
import {productCategoryUrl, postClusterUrl, blogUrl, findAncestorByClassNameInEventPath, handleInnerNavigation} from "../helpers";
import {slice, concat} from 'lodash';
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";

class ProbioticsPage extends React.Component {
  constructor(props) {
    super(props)
    const title = 'Probiotics For Pets | Honest Paws®'
    this.pageUrl = this.props.location.href
    this.posts = get(this.props, 'data.posts.edges', []) || []
    this.ogImage = this.props.data.ogImage.childImageSharp.fluid

    this.state = {
      breadcrumbs_visible: false,
      offset: 110,
      postsVisbles: 9,
      postsPerPage: 9,
      showMoreButton: true
    }

    this.seo = {
      title: title,
      meta: {
        title: title,
        description: 'Find out more about how probiotics can be a great way to enhance your pet’s digestion to ensure a healthy gut.',
        image: this.ogImage
      },
      children: [
        <link rel="canonical" href={ this.pageUrl } key='canonical-url'  />
      ],
      disallowIndexing: true
    }

    this.handleBreadcrumbsTrigger = this.handleBreadcrumbsTrigger.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.showMorePosts = this.showMorePosts.bind(this)

    this.breadcrumbs = [
      buildBreadcrumbsItem('Probiotics', blogUrl(null, 1, this.props.location.origin), this.handleBreadcrumbsTrigger)
    ]

    this.rightContainer = null
    this.svgContainer = null
  }

  /**
   * Handles breadcrumbs box visualization
   * @param  {Event} e
   * @return {void}
   */
  handleBreadcrumbsTrigger(e) {
    this.setState({
      breadcrumbs_visible: !this.state.breadcrumbs_visible
    })
  }

  /**
   * Handles click event so we can close the cart when it a click is made outside it
   * @param  {event} e
   * @return {void}
   */
  handleDocumentClick(e) {
    // These are all the classes that will prevent this from happening
    if (!findAncestorByClassNameInEventPath(e, ['.btn-toggle-breadcrumbs', '.breadcrumbs-list-container']) && this.state.breadcrumbs_visible) {
      this.handleBreadcrumbsTrigger()
    }
  }

  /**
   * Displays More Posts - Pagination
   */
  showMorePosts () {
    this.setState({
      postsVisbles: this.state.postsVisbles + this.state.postsPerPage,
      showMoreButton: this.posts.length > (this.state.postsVisbles + this.state.postsPerPage)
    })
  }

  componentDidMount() {
    var windowWidth = window.innerWidth

    if (windowWidth >= 1366) {
      var windowHeight = window.innerHeight
      var rightContainerHeight = windowHeight - 44

      var svgContainerHeight = rightContainerHeight - 210

      this.rightContainer.style.height = `${rightContainerHeight}px`
      this.svgContainer.style.height = `${svgContainerHeight}px`
    }

    this.setState({
      showMoreButton: this.posts.length > this.state.postsPerPage
    })
    document.addEventListener('click', this.handleDocumentClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  render() {


    return (
      <Layout location={this.props.location} containerClassName={`page page-probiotics`} hideFooter={true} hideHeader={true} bodyClassName="chat-hidden">
        <SEO { ...this.seo } />
        <nav className="breadcrumb-container w-100 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 mx-auto breadcrumbs-component-container">
                <button className="btn btn-toggle-breadcrumbs mb-2" onClick={this.handleBreadcrumbsTrigger}><span>...</span></button>
                <Breadcrumbs items={ this.breadcrumbs } location={ this.props.location }
                             className={`breadcrumbs-list-container ${!this.state.breadcrumbs_visible ? 'd-none' : ''}`}/>
              </div>
            </div>
          </div>
        </nav>

        <StickyShare className="probiotics-sticky-share" shareDialogTitle={this.seo.meta.title} shareDialogText={this.seo.meta.description} />

        <section className="probiotics-container py-0">
          <div className="row m-0">
            <div className="col-12 col-md-6 col-lg-5 mx-lg-auto pr-md-0">
              <h1 className="col-12">Probiotics</h1>
              <div className="col-12 pt-3 subtitle">This non-GMO, human grade, proprietary blend can help your dog maintain proper digestion and promote gut health.</div>

              <div className="btn-container mt-4 mt-md-5 mb-4 mb-md-0 d-flex">
                <div className="col-6">
                  <a href='https://www.honestpaws.com/products/probiotics-for-dogs/' target="_top" state={{prevPath: this.props.location.pathname, prevPathName: 'probiotics'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/probiotics/blue-dog.svg" className="mx-auto mb-3" alt="Probiotics for Dogs" data-lazy-class="img-solid" loading="lazy" />
                    Probiotics <br className="d-lg-none"/>for Dogs
                  </a>
                </div>
                <div className="col-6">
                  <a href='https://www.honestpaws.com/probiotics/probiotics-for-cats/' target="_top" state={{prevPath: this.props.location.pathname, prevPathName: 'cbd'}}
                        className="btn btn-outline px-1">
                    <img src="/img/hub-clusters/probiotics/blue-cat.svg" className="mx-auto mb-3" alt="Probiotics for Cats" data-lazy-class="img-solid" loading="lazy" />
                    Probiotics <br className="d-lg-none"/>for Cats
                  </a>
                </div>
              </div>

            </div>
            <div className="recommended-container col-12 col-md-6 col-lg-6 p-0" ref={ (ref) => this.rightContainer = ref }>
              <div className="svg-container" ref={ (ref) => this.svgContainer = ref }></div>
              <a href="#blog-posts-container" className="down-arrow d-none d-md-block" onClick={(e) => handleInnerNavigation(e, -this.state.offset)}></a>
              <div className="col-12 col-lg-8 mx-auto my-6 my-md-5">
                <div className="mb-3"><strong>Recommended for:</strong></div>
                <ul>
                  <li>Supporting healthy digestion</li>
                  <li> Helping support the immune system</li>
                  <li>Helping maintain proper gut flora</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white pt-0 px-lg-4" id="blog-posts-container">
          <div className="page-content-container">
            <div className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 px-0 mt-5 d-flex flex-wrap">
              {this.posts.slice(0, this.state.postsVisbles).map(({node}, index) => {
                return <PostCard
                  title={ node.title }
                  url={ postClusterUrl(node) }
                  image={ node.heroImage }
                  shortDescription={ node.shortDescription }
                  key={ index }
                  version={2}
                  bgColor="bg-light"
                  className="col-12 col-sm-6 col-xl-4 mb-5" />
              })}
              <div className="col-12 text-center">
                <button onClick={this.showMorePosts} className={`btn btn-outline show-more ${!this.state.showMoreButton ? "d-none" : ""}`}>Show More</button>
              </div>
            </div>
          </div>
        </section>

        <section className="img-woman-and-dog"></section>

      </Layout>
    )
  }
}

export default ProbioticsPage

export const ProbioticsPageQuery = graphql`
  query ProbioticsPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    posts: allContentfulBlogPost(filter: {cluster: {eq: "probiotics"}}) {
      edges {
        node {
          ...contentfulBlogPost
        }
      }
    }
    ogImage: file(relativePath: { eq: "hub-clusters/cbd/og-image-cbd-cluster.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
