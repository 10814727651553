import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { truncate } from 'lodash'
import { FiHome } from "react-icons/fi";
import styles from './breadcrumbs.scss'
import { extractBreadcrumbsStructuredDataJsonFromLocation } from '../../helpers'

export default (props) => {
  const { items, className, location } = props
  return (
    <>
      <Helmet> 
        <script type='application/ld+json'>
            { JSON.stringify(extractBreadcrumbsStructuredDataJsonFromLocation(items)) }
        </script>
      </Helmet>
      <div className={`breadcrumbs-container ${className || ''}`}> 
        <nav className='container' title="Breadcrumbs Navigation">
          <ul className='breadcrumbs'>
            <li>
              <Link to='/' title="Go To Homepage"><span className="d-flex align-items-center"><FiHome title="Go To Homepage"/></span></Link>
            </li>
            { 
              items.map(({name, path, callback}, index) => {
                const tName = items[index+1] ? truncate(name, {length: 45}) : truncate(name, {length: 20})
                const attrs = {}
                if (callback) {
                  attrs.onClick = callback
                }
                return name && path ? (
                  <li key={ index }>
                    <Link to={path.replace(location.origin, '')} activeClassName="active" { ...attrs } className={index === items.length -1 ? 'disabled-link' : ''}>
                      <span className="d-none d-md-inline">{ name }</span>
                      <span className="d-md-none">{ tName }</span>
                    </Link>
                  </li>) : ''
              })
            }
          </ul>
        </nav>
      </div>
    </>
  )
}
